module.exports = [{
      plugin: require('/Users/kmamykin/Projects/mamykin.com/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/kmamykin/Projects/mamykin.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/kmamykin/Projects/mamykin.com/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-20522581-6"]},
    },{
      plugin: require('/Users/kmamykin/Projects/mamykin.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
